import { useEffect } from "react";
import AOS from "aos";
import Marquee from "react-fast-marquee";
import LogoApp from "./assets/img/logo-app.png";
import IconTwitter from "./assets/img/icon-twitter.png";
import IconTelegram from "./assets/img/icon-telegram.png";
import IconPresent from "./assets/img/icon-present.png";
import ImgIntro1 from "./assets/img/img-intro-1.png";
import ImgIntro2 from "./assets/img/img-intro-2.png";
import ImgSanta from "./assets/img/img-santa.png";
import ImgUtility from "./assets/img/img-utility.png.png";
import ImgSantaTotal from "./assets/img/img-santa-total.png";
import ImgToken from "./assets/img/img-title-token.png";
import ImgBannerToken from "./assets/img/img-banner-token.png";
import ImgBox1 from "./assets/img/img-box-1.png";
import ImgBox2 from "./assets/img/img-box-2.png";
import ImgBox3 from "./assets/img/img-box-3.png";
import ImgBox4 from "./assets/img/img-box-4.png";
import ImgSlider1 from "./assets/img/img-slider-1.png";
import ImgSlider2 from "./assets/img/img-slider-2.png";
import ImgSlider3 from "./assets/img/img-slider-3.png";
import ImgBoard from "./assets/img/img-board.png";
import ImgButtonLink from "./assets/img/img-btn-link.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  const handleScrollToSection = (key) => {
    const sectionUtility = document.querySelector(".section-utility");
    const sectionTokenomics = document.querySelector(".section-tokenomics");
    switch (key) {
      case 1:
        return sectionUtility.scrollIntoView({ behavior: "smooth" });
      default:
        return sectionTokenomics.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="App">
      <header className="page-header">
        <div className="container">
          <div className="logo-app">
            <img src={LogoApp} alt="logo-app" />
          </div>
          <ul className="nav-menu">
            <li>
              <a
                href="https://t.me/MerryXMas_TG"
                target="_blank"
                rel="noopener noreferrer"
              >
                JOIN NOW
              </a>
            </li>
            <li onClick={() => handleScrollToSection(1)}>
              <a href="#">UTILITY</a>
            </li>
            <li onClick={() => handleScrollToSection(2)}>
              <a href="#">TOKENOMICS</a>
            </li>
          </ul>
          <div className="socials">
            <a
              href="https://t.me/MerryXMas_TG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconTelegram} alt="" />
            </a>
            <a
              href="https://twitter.com/MerryXMas_X"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconTwitter} alt="" />
            </a>
          </div>
        </div>
      </header>
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container">
            <div className="img-intro-1">
              <img src={ImgIntro1} alt="" />
            </div>
            <div className="img-intro-2">
              <img src={ImgIntro2} alt="" />
            </div>
          </div>
          <div className="img-present">
            <img src={IconPresent} alt="" />
          </div>
        </section>
        <section className="section-content">
          <div data-aos="fade-up" className="container">
            <h2 className="title">just in time</h2>
            <h3 className="subtitle">For the Holidays</h3>
            <div className="img-santa">
              <img src={ImgSanta} alt="" />
            </div>
            <div className="content-wrapper">
              <div className="img-board">
                <img src={ImgBoard} alt="" />
              </div>
              <div className="content">
                <h3 className="title">WHAT MAKES MERRY XMAS DIFFERENT?</h3>
                <p className="des">
                  Emerging from the shadows of skepticism and mistrust, Merry
                  Xmas emerges as a beacon of hope for those who have lost faith
                  in the cryptocurrency realm. Witness to the devastating impact
                  of scams and fraudulent schemes, we are driven by an
                  unwavering mission: to rekindle trust and establish a secure
                  haven for investors. Merry Xmas is more than just a meme
                  token; it's a movement, a community-driven initiative that
                  prioritizes safety, transparency, and unwavering support. We
                  believe that the cryptocurrency space deserves better, a place
                  where integrity and ethical practices reign supreme.
                </p>
                <div className="socials">
                  <div className="img-btn-link">
                    <img src={ImgButtonLink} alt="" />
                    <a
                      href="https://t.me/MerryXMas_TG"
                      className="btn-telegram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      telegram
                    </a>
                  </div>
                  <div className="img-btn-link">
                    <img src={ImgButtonLink} alt="" />
                    <a
                      href="https://twitter.com/MerryXMas_X"
                      className="btn-twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      twitter
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-utility">
          <div data-aos="fade-up" className="container">
            <div className="img-utility">
              <img src={ImgUtility} alt="" />
            </div>
            <div className="box-wrapper">
              <p className="des">
                Merry Xmas has invented a Telegram bot that selects holders at
                specific times for a gift! These will be collected and
                distributed during our daily voice chats.
              </p>
            </div>
            <div className="img-santa-total">
              <img src={ImgSantaTotal} alt="" />
              <div className="content">
                <h3>Total Winners</h3>
                <p>0</p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-tokenomics">
          <div data-aos="fade-up" className="container">
            <div className="img-token">
              <img src={ImgToken} alt="" />
            </div>
            <div className="img-banner-token">
              <img src={ImgBannerToken} alt="" />
              <p>5ZUVaCjjTTvqx4MiWZnMWEzkcQKL6X6pyChPPtNFyZ2o</p>
            </div>
            <ul className="list-present">
              <li className="item-box">
                <img src={ImgBox1} alt="" />
                <p>100 M</p>
              </li>
              <li className="item-box">
                <img src={ImgBox2} alt="" />
                <p>100 %</p>
              </li>
              <li className="item-box">
                <img src={ImgBox3} alt="" />
                <p>1 %</p>
              </li>
              <li className="item-box">
                <img src={ImgBox4} alt="" />
                <p>0</p>
              </li>
            </ul>
          </div>
        </section>
        <section className="section-slider">
          <Marquee speed={40} gradient={false}>
            <div className="item-slide">
              <img src={ImgSlider1} alt="Image 1" />
            </div>
            <div className="item-slide">
              <img src={ImgSlider2} alt="Image 2" />
            </div>
            <div className="item-slide">
              <img src={ImgSlider3} alt="Image 3" />
            </div>
          </Marquee>
        </section>
      </main>
      <footer className="page-footer">
        <div className="container">
          <p>© Powered by Santa Claus</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
